<template>
  <div class="chart -mt-9 flex items-center justify-center pb-14">
    <div class="chart__content flex justify-center">
      <div
        v-for="(item, index) in chartData"
        :key="index"
        class="chart__bar-container flex flex-col items-center"
        :style="{ '--total-items': chartData.length }"
      >
        <div
          class="chart__bar relative flex max-h-64 min-w-[50px] max-w-[150px] flex-col justify-end bg-slate-50 md:max-h-96"
        >
          <div
            class="chart__bar-fill w-full"
            :style="{ '--bar-height': item.percentage + '%' }"
          ></div>
          <div
            class="chart__bar-label absolute top-[5%] flex w-full flex-col items-center"
          >
            <div class="chart__label-text hidden text-center text-xs md:block">
              {{ item.label }}
            </div>
            <div
              class="chart__percentage mb-1/2 text-xl font-medium md:text-3xl"
            >
              {{
                locale === 'en-us'
                  ? item.percentage + '% '
                  : item.percentageLabel
              }}
            </div>
          </div>
        </div>
        <div
          class="chart__mobile-label mt-2.5 flex rotate-180 justify-center whitespace-normal text-xs text-white md:hidden"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    data: {
      type: Array,
      required: true,
    },
  });

  const locale = useI18n();

  const chartData = ref(
    props.data.map((stat, index) => {
      return {
        label: stat.fields?.statisticName || '',
        percentage: stat.fields?.statisticPercentage || 0,
        percentageLabel: stat.fields?.statisticLabel || '',
      };
    })
  );

  const hasMounted = ref(false);

  onMounted(() => {
    // Trigger the animation after the component is mounted
    hasMounted.value = true;
  });
</script>

<style lang="scss" scoped>
  .chart__bar-container {
    &:not(:last-child) {
      margin-right: 1.5rem;
      @media (max-width: 768px) {
        margin-right: 1rem;
      }
      @media (max-width: 480px) {
        margin-right: 0.5rem;
      }
    }
  }

  .chart__bar {
    width: calc(65vw / var(--total-items));
    height: calc(100vh - 50px);
  }

  .chart__bar-fill {
    background-color: #3b82f6;
    animation: growBar 1s ease-in-out forwards;
  }

  .chart__mobile-label {
    writing-mode: vertical-lr;
  }

  @keyframes growBar {
    from {
      height: 0;
    }
    to {
      height: var(--bar-height);
    }
  }
</style>
